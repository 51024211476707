@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Black.woff') format('woff2'),
        url('../font/ConfigCondensed-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Italic.woff2') format('woff2'),
        url('../font/ConfigCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-BlackItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-LightItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-ExtraLight.woff2') format('woff2'),
        url('../font/ConfigCondensed-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-ExtraBold.woff2') format('woff2'),
        url('../font/ConfigCondensed-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Black.woff2') format('woff2'),
        url('../font/ConfigCondensed-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-ExtraLightItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Medium.woff2') format('woff2'),
        url('../font/ConfigCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Light.woff2') format('woff2'),
        url('../font/ConfigCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-ExtraBoldItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Bold.woff2') format('woff2'),
        url('../font/ConfigCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-BoldItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-SemiBoldItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-ThinItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed Text';
    src: url('../font/ConfigCondensed-TextItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-TextItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-MediumItalic.woff2') format('woff2'),
        url('../font/ConfigCondensed-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Regular.woff2') format('woff2'),
        url('../font/ConfigCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed Text';
    src: url('../font/ConfigCondensed-Text.woff2') format('woff2'),
        url('../font/ConfigCondensed-Text.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-SemiBold.woff2') format('woff2'),
        url('../font/ConfigCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Config Condensed';
    src: url('../font/ConfigCondensed-Thin.woff2') format('woff2'),
        url('../font/ConfigCondensed-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

