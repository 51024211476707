body{
    font-family: 'Config Condensed' !important;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
}
.fSize-60{
font-size: 60px;

}
.fSize-56{
    font-size: 56px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 10px;
    }
.customcontainer{
    max-width: 1640px;
}
.footercontainer{
    max-width: 1850px;
    a{
        text-decoration: none;
    }
}
.customgray{
color: #5F6064;
}
.custommaargin{
    margin-top: 75px;
}
.customheader{
    background-color:#ffff;
    padding: 15px 0px;
}
.loginbtn{
    background-color: #5F6064;
    color: #ffff;
    font-weight: 700;
    border-radius: 0%;
    border: none;
    padding: 5px 15px;
    font-size: 20px;
    &:hover{
      background-color: rgba($color: #5F6064, $alpha: 0.8);
    }
}

.customarrowleft{
    left: -60px;
    opacity: 0.9;
    background: #ffff;
    color: #707070;
}
.customarrowright{
    right: -60px;
    opacity: 0.9;
    background: #ffff;
    color: #707070;
    
}
.footerline{
    color: #5F6064;
}
.customlist{
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    a{
        color: #5F6064;
    }
}
.leftposition{
    position: absolute;
    color: #FFFF;
    bottom: 50px;
    left:40px;
    font-size: 18px;
}
.rightposition{
    position: absolute;
    color: #FFFF;
    bottom: 50px;
    right:40px;
    text-align: right;
    font-size: 18px;
}
.centerposition{
    position: absolute;
    top: 40%;
    color: #FFFF;
    font-size: 30px;
    left:20%;
    right: 20%;
    width: auto;
    margin: 0 auto;
}
.centerposition-head{
  position: absolute;
  color: #FFFF;
  font-size: 30px;
  top: 40%;
  left:50%;
  right: 10%;
  width: auto;
  margin: 0 auto;
}
.leftsechead{
  color: #FFFF;
  font-size: 18px;
}
.Rightsechead{
  color: #FFFF;
  font-size: 18px;
  text-align: right;
}

.fontspace{
    letter-spacing: 30px;
}
.fontspacehead{
  letter-spacing: 20px;
  margin-right: -20px;
}
.customhr{
    border: 2px solid #ffff;
}
.customtop{
    margin-top: 100px;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 86.25%; /* 16:9 aspect ratio */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-containerhead {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 45.25%; /* 16:9 aspect ratio */
}

.video-containerhead video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dashVideoContent {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
}
.goodslogo {
  width: 100px;
}
//  == form css ==
  .login-box .user-box input {
    width: 100%;
    padding: 5px 0;
    margin-bottom: 30px;
    border: none;
    border-bottom: 4px solid #5F6064;
    outline: none;
    font-size: 20px;
    font-weight: 700;
    color: #5F6064;
  }  
    // ===========

// === responsive page ====


@media (min-width: 320px) {
    .fSize-60 {
      font-size: calc(1.875rem + ((1vw - 3.2px) * 1.875));
     
    }
    .fSize-56 {
      font-size: calc(1.5rem + ((1vw - 3.2px) * 2));
   
    }
      .loginbtn {
        font-size: calc(0.875rem + ((1vw - 3.2px) * 0.375));
     
      }
     
      .leftposition {
        font-size: calc(0.875rem + ((1vw - 3.2px) * 0.25));
      
      }
      .rightposition{
          font-size: calc(0.875rem + ((1vw - 3.2px) * 0.25));
      }
      .centerposition {
        font-size: calc(1.25rem + ((1vw - 3.2px) * 0.625));
      
      }
      .login-box .user-box input {
        font-size: calc(1rem + ((1vw - 3.2px) * 0.25));
     
      }
      .leftsechead{
        font-size: calc(0.875rem + ((1vw - 3.2px) * 0.25));      
     } 
     .Rightsechead{
       font-size: calc(0.875rem + ((1vw - 3.2px) * 0.25));
     }
     .centerposition-head {
      font-size: calc(1rem + ((1vw - 3.2px) * 0.875));
    }
  }

  @media (max-width: 1440px) {
    .customarrowright{
      right: 0;
      opacity: 1;
      background: #ffff;
      color: #948f8f;
      
  }
  .centerposition-head{
    top: 40%;
    left:40%;
    right: 10%;
   
  }
  }
  @media (max-width: 992px){
    .goodslogo{
      width: 80px;
    }
    .navbar-toggler-icon{
      width: 20px !important;
      height: 20px !important;
    }
    .navbar-toggler{
      padding: 2px 5px;
    }
    .navbar-nav{
      align-items: center;
    }
    .navbar{
      --bs-navbar-toggler-focus-width:2px
    }
  }

  @media (max-width: 792px){
    .customlogo{
        width: 40%;
        img {
            max-width: 100%;
        }
    }
    .leftposition{
      bottom: 10px;
      left:30px;
    }
  .rightposition{
    bottom: 10px;
    right:30px;
  }
  .goodslogo{
    width: 60px;
  }
  .centerposition-head{
    top: 20%;
    left:30%;
    right: 10%;
  }
  }
  @media (max-width: 640px) {
    .centerposition-head{
      top: 20%;
      left:20%;
      right: 10%;
    }
    .fontspacehead{
      letter-spacing: 10px;
      margin-right: -10px;
    }
  }
  @media (max-width: 520px) {
    .centerposition{
        left:10%;
        right: 10%;
    }
    .fontspace{
        letter-spacing: 20px;
    }
    .custommaargin{
      margin-top: 100px;
    }
    .goodslogo{
      width: 40px;
    } 
    .centerposition-head{
      top: 10%;
      left:20%;
      right: 10%;
      
    }
    .fSize-56{
      line-height: 20px;
    }
  .leftsechead {
    line-height: 15px;
    margin-top: 20px;
}
.Rightsechead{
  line-height: 15px;
    margin-top: 20px;
}
  }
  
  
.page-link {
color: #5F6064 !important;
border-radius: 0% !important;
  }